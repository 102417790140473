<template>
  <el-form
    :model="addForm"
    :rules="rules"
    ref="addForm"
    label-width="82px"
    label-position="left"
    class="demo-form dialog-form"
  >
    <!-- 用户姓名： -->
    <el-form-item label="用户姓名：" prop="realname">
      <el-input v-model.trim="addForm.realname" placeholder="请输入用户姓名"></el-input>
    </el-form-item>
    <!-- 用户名： -->
    <el-form-item label="用户名：" prop="username">
      <el-input v-model.trim="addForm.username" placeholder="请输入用户名"></el-input>
    </el-form-item>
    <!-- 性别 -->
    <el-form-item label="性别：" prop="gender">
      <el-select v-model="addForm.gender" placeholder="请选择性别">
        <el-option label="男" :value="1"></el-option>
        <el-option label="女" :value="0"></el-option>
      </el-select>
    </el-form-item>
    <!-- 密码： -->
    <el-form-item label="密码：" prop="password">
      <el-input v-model.trim="addForm.password" type="password" placeholder="请输入密码"></el-input>
    </el-form-item>
    <!-- 联系方式： -->
    <el-form-item label="联系方式：" prop="phone">
      <el-input v-model.trim="addForm.phone" placeholder="请输入手机号码"></el-input>
    </el-form-item>
    <!-- 邮箱： -->
    <el-form-item label="邮箱：" prop="email">
      <el-input v-model="addForm.email"></el-input>
    </el-form-item>
    <!-- 所属部门： -->
    <el-form-item label="所属部门：" prop="deptId">
      <el-input v-model.trim="addForm.deptId"></el-input>
    </el-form-item>
    <!-- 角色： -->
    <el-form-item label="角色：" prop="role">
      <el-input v-model.trim="addForm.role"></el-input>

      <!-- <el-select v-model="addForm.role" multiple collapse-tags placeholder="请选择所属角色">
        <el-option
          v-for="role in roleList"
          :label="role.value"
          :value="role.key"
          :key="role.key"
          :disabled="role.status===1"
        ></el-option>
      </el-select>-->
    </el-form-item>
    <!--  用户描述：-->
    <el-form-item label="用户描述：">
      <el-input v-model.trim="addForm.description" type="textarea"></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
import { checkPhone, checkUsername, checkPassword, checkName } from '@/common/utils/index.js'
import {

  insertDevice,
  updateDevice,
  queryClientsById

} from '@/api/lib/api.js'
export default {
  props: {
    clientId: {
      type: Number
    }
  },
  data () {
    return {
      addForm: {
        "avatar": "default",
        "deptId": 1,
        "description": "测试角色",
        "email": "qwe@qq.com",
        "gender": 0,
        "password": "123456",
        "phone": "15011110000",
        "realname": "",
        "role": '',
        "status": 1,
        "username": ""
      },
      rules: {
        realname: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
          { validator: checkName, trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" }
        ],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { validator: checkUsername, trigger: "blur" }
        ],
        gender: [
          { required: true, message: "请选择性别", trigger: "blur" },
        ],

        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "密码为6-18位数字或字母",
            trigger: "blur"
          },
          { validator: checkPassword, trigger: "blur" }
        ],
        deptId: [
          { required: true, message: "请选择所属公司", trigger: "blur" }
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱地址",
            trigger: "blur"
          }
        ],
        userGroupId: [
          { required: true, message: "请选择用户组", trigger: "blur" }
        ],
        role: [
          { required: true, message: "请选择所属角色", trigger: "blur" }
        ]
      },
      roleList: []
    };
  },
  methods: {
    // 新增/保存用户
    onSave () {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          let data = {
            realname: this.addForm.realname,
            username: this.addForm.username,
            password: this.addForm.password,
            phone: this.addForm.phone,
            deptId: this.addForm.deptId,
            email: this.addForm.email,
            role: [1],
            description: this.addForm.description,
            avatar: "default",
            gender: this.addForm.gender === 0,

          };
          if (this.clientId === null) {
            data = {
              ...data,
              status: 0,
            };
            insertDevice(data).then(res => {
              if (res.code === 0) {
                this.$message.success('新增成功')
              } else {
                this.$message.error('新增失败')
              }
              this.$emit("onDialogClose", res.code === 0 ? true : false)

            })
          } else {
            data = {
              ...data,
              id: this.addForm.id,
              status: this.addForm.status
            };
            updateDevice(data).then(res => {
              if (res.code === 0) {
                this.$message.success('修改成功')
              } else {
                this.$message.error('修改失败')
              }
              this.$emit("onDialogClose", res.code === 0 ? true : false)
            })
          }
        }
      });
    }
  },
  created () {
    if (this.clientId) {
      queryClientsById(this.clientId).then(res => {
        if (res.code === 0) {
          this.addForm = { ...res.data };
        }
      })
    }
  },
  mounted () { }
};
</script>