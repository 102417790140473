 <template>
  <div class="client-management" ref="clientManagement">
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item>
          <el-button type="primary" size="small" @click="onAddClient">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      stripe
      :height="tableHeight"
    >
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="accessTokenValidity" label="1"></el-table-column>
      <el-table-column prop="additionalInformation" label="1"></el-table-column>
      <el-table-column prop="authorities" label="	11	"></el-table-column>
      <el-table-column prop="authorizedGrantTypes" label="1"></el-table-column>
      <el-table-column prop="autoapprove" label="2"></el-table-column>
      <el-table-column prop="clientSecret" label="3"></el-table-column>
      <el-table-column prop="refreshTokenValidity" label="4"></el-table-column>
      <el-table-column prop="resourceIds" label="5"></el-table-column>
      <el-table-column prop="tenantId" label="6"></el-table-column>
      <el-table-column prop="webServerRedirectUri" label="7"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit-outline"
            type="text"
            size="small"
            sort="primary"
            @click="onEditClient(scope.row)"
          >编辑</el-button>
          <el-button
            icon="el-icon-delete"
            type="text"
            size="small"
            sort="danger"
            @click="onDeleteClient(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <el-dialog :title="title" v-if="dialogVisible" :visible.sync="dialogVisible" width="30%">
      <div>
        <div class="body"></div>
        <addClient ref="addClient" :clientId="clientId" @onDialogClose="onDialogClose" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSave"
        >{{ title.indexOf("新增") !== -1 ? "新增" : "保存"}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

  <script>
import addClient from "./components/addClient.vue";
import { getDeviceList, deleteDevice } from "@/api/lib/api.js";
export default {
  name: "clientManagement",
  components: {
    addClient
  },
  data () {
    return {
      tableHeight: 0,
      total: 10,
      tableData: Array.from({ length: 10 }, (_, idx) => ({
        id: idx + '_' + 1,
        accessTokenValidity: '2016-05-03',
        additionalInformation: 1,
        authorities: idx,
        authorizedGrantTypes: idx,
        autoapprove: idx,
        clientSecret: idx,
        refreshTokenValidity: idx
      })),
      form: {
        current: 1,
        size: 10
      },
      multipleSelection: [],
      title: "",
      clientId: null,
      dialogVisible: false
    };
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.clientManagement.clientHeight;
      let formHeight = this.$refs.form.clientHeight;
      let paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight - 16 - formHeight - paginationHeight - 10 - 16 - 35;
    },
    // 点击查询
    onSearch () {
      this.form.current = 1;
      this.getClientListByField(this.form);
    },
    //获取数据
    getClientListByField (data) {
      // getDeviceList(data).then(res => {
      //   if (res.code === 0) {
      //     this.tableData = res.data.records;
      //     this.total = res.data.total;
      //     if (!res.data.records.length && this.form.current !== 1) {
      //       //如果当前页的数据为0则设置页面为前一页 但是确保当前页不为1
      //       self.form.current -= 1;
      //       self.getClientListByField(this.form);
      //     }
      //   } else {
      //     this.tableData = [];
      //     this.total = 0;
      //     this.$message.error(res.message);
      //   }
      // });
    },
    //新增
    onAddClient () {
      this.title = "新增角色";
      this.clientId = null;

      this.dialogVisible = true;
    },
    onEditClient (row) {
      this.title = "编辑角色";
      this.clientId = row.clientId;

      this.dialogVisible = true;
    },
    // 禁用|启用 用户
    onDeleteClient (row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "确定删除角色 "),
          h("i", { style: "color: #059370" }, row.name),
          h("span", null, " ？")
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          deleteDevice(row.clientId).then(res => {
            if (res.code === 0) {
              this.$message.success("删除成功");
              this.getClientListByField(this.form);
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除"
          });
        });
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.current = page;
      this.getClientListByField(this.form);
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.size = size;
      this.getClientListByField(this.form);
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    // 弹窗关闭时的回调
    onDialogClose (flag) {
      this.dialogVisible = false;
      if (flag) {
        this.getClientListByField(this.form);
      }
    },
    onSave () {
      this.$refs.addClient.onSave();
    }
  },
  created () {
    this.onSearch();
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  }
};
</script>
<style lang="scss" scoped>
.client-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  .avatarImg {
    vertical-align: middle;
    border-radius: 50%;
  }
}

.theme-light {
  .client-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>